import { navigate } from "gatsby";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Layout, SingleRide } from "../components";
import { fetchSingleRide } from "../state";
import { AgenciesSection } from "../style";
import { BallBeat } from "react-pure-loaders";

function Ride({
  location = {},
  rideDetails = {},
  rideUpdateMsg,
  fetchSingleRide,
  loadingride,
  paymentsMsg,
}) {
  // if (location.state === null || undefined) {
  //   // console.log(location.state)
  //   navigate("/rides");
  // }
  var rideId;
  useEffect(() => {
    // if (location.state === null || undefined) {
    //   navigate("/rides");
    // }
    // console.log("fetchSingleRide being called", location.state.data.id);
    rideId = location.state.data.id;
    fetchSingleRide(rideId);
  }, [fetchSingleRide]);
  // console.log("ride from navigation", location.state);

  return (
    <div>
      <Layout>
        <AgenciesSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                typeof history !== "undefined" && history.go(-1);
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              {rideDetails.bidStatusId == "100140002"
                ? "New Booking"
                : rideDetails.bidStatusId == "100140012"
                ? "Partial Cancel"
                : rideDetails.bidStatusId == "100140007"
                ? "Cancelled"
                : rideDetails.bidStatusId == "100140005" &&
                  rideDetails.rideStatusId == "100110007"
                ? "Confirmed"
                : rideDetails.bidStatusId == "100140005" &&
                  rideDetails.rideStatusId == "100110002"
                ? "Ongoing"
                : rideDetails.bidStatusId == "100140005" &&
                  rideDetails.rideStatusId == "100110004"
                ? "Completed"
                : rideDetails.bidStatusId == "100140005"
                ? "Place Holder"
                : ""}{" "}
              Rides
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              &#8827;
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Ride ID: {location.state ? location.state.data.id : " "}
            </p>
          </div>
          <h1>
            Ride Details of{" "}
            <span
              style={{
                backgroundColor: "yellow",
              }}
            >
              #{location.state ? location.state.data.accountSeqId : " "}
            </span>
          </h1>

          {loadingride ? (
            <h4 style={{ marginTop: "2rem" }}>
              Loading details{" "}
              <span>
                <BallBeat color={"#123abc"} loading={loadingride} />
              </span>{" "}
            </h4>
          ) : (
            <SingleRide
              rideData={location.state && location.state.data}
              rideDetails={rideDetails}
              rideUpdateMsg={rideUpdateMsg}
              location={location}
              paymentsMsg={paymentsMsg}
            />
          )}
        </AgenciesSection>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    rideDetails: state.rides.singleride,
    rideUpdateMsg: state.rides.singlerideupdatemsg,
    loadingride: state.rides.loadingride,
    paymentsMsg: state.rides.paymentsMsg,
  };
};

const mapDispatchToProps = (dispatch, rideId) => {
  return {
    fetchSingleRide: () => dispatch(fetchSingleRide(rideId.location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Ride);
